@import '../theme';

.extra-button {
  padding-top: 1rem;
}

.container {
  .panel {
    margin: 2rem;

    .title {
      margin-left: 0.8rem;

      &::before {
        content: '\2713';
        width: 1.5em;
        height: 1.5em;
        text-align: center;
        position: absolute;
        border-radius: 1.5rem;
        left: -1.3rem;
        font-size: 30px;
        color: #fff;
        background-color: $primary;
      }

      .required {
        color: #808080;
      }
    }

    .row-data {
      border-left: 1px solid #808080;
    }

    .button-panel {
      text-align: right;
      padding: 0;
      padding-right: 7px;
      margin: 0.5rem 0;

      .btn {
        margin: 0 0.5rem;
      }
    }
  }
}

.line-separator {
  border-top: 1px solid #e0e0e0;
  width: 100%;
  padding: 1rem;
  margin: 1rem;
}

.btn-code {
  margin-bottom: 1rem;
}
