.tag{
  display: flex;
  border-radius: 10px;
  border-color: #eee;
  background-color: #eee; 
  border: none;
  color: black;
  padding: 1px 1px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  button{
    margin-left: 2px;
    border: none;
    border-radius: 10px;
    background-color: #eee; 
  }
  button:hover{
    border: none;
    border-radius: 10px;
    background-color: #eee; 
  }
}
.home-page-container {
  flex: 10;
  overflow-y: scroll;
  scrollbar-width: none;
  .tab-content {
    padding-top: 1.5rem;
  }
}
.home-page-container::-webkit-scrollbar {
  display: none; 
}
.go-last-card {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background: #555;
  margin: 0.75rem;
  padding: 1rem;
  border-radius: 50%;
}

.copyright-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  .txt{
    font-size: 10px;
  }
  :first-child{
    margin:0px;
  }
  :nth-of-type(2){
    margin:0px 0px 0.5em 0px;
  }
}
.modal-dialog{
  margin: 0px!important;
}
.modal-content{
  height: 100vh!important;
  max-height: 100vh;
  display: flex!important;
  flex-direction: column!important;
}
.modal-header{
  display: flex;
  flex: 1;
}
.modal-body{
  display: flex;
  flex: 3;
}
.modal-footer{
  display: flex;
  flex: 1;
  justify-content: center!important;
}
