.new-content {
  text-align: center;

  & > div {
    left: 0;
    position: fixed;
    z-index: 1000;
    color: white;
    margin: 0 auto;
    background: #0099cc;
    padding: 1rem;
    width: 100%;
  }
}
