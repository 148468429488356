@import '../../theme';

.txt-center {
  text-align: center;
}

.avatar {
  background-color: $teal;
  color: #fff;
  border-radius: 50%;
  padding: 1.5rem;
  font-size: 4rem;
  font-weight: bold;
}

.last-logout {
  position: fixed !important;
  bottom: 6rem;
}
.btn-width{
  width: 13.04em;
}
.txt-exit{
  margin: 0px;
}

.app-name {
  font-size: 2.5rem;
  color: #000;
  font-weight: bold;
}

.highlighted {
  color: #4ebab2;
}
.menu-container{
  display: flex;
  flex-direction: column;
  padding-left: 30px!important;
  padding-right: 30px!important;
}
.menu-content{
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal{
  display: flex;
  padding-left: 0px!important;
}
.modal-content{
  width: 100vw!important;
  height: 100vh!important;
}

.menu-container-item-1{
  flex: 1;
  .logo{
    height: 75px;
  }
}
.menu-container-item-2{
  flex: 2;
}
.menu-container-item-3{
  display: flex;
  flex-direction: column;
  flex: 13;
  .username-container{
    flex:3;
    text-transform: capitalize;

  }
  .btn-container{
    flex:1
  }
  .version-container{
    flex:1;
    color:#000;
  }
  .btn-salir-container{
    flex:2;
  }
}

.font{
  font-size: 0.9em!important;
}


@media (max-width: 600px) {
  .modal-header{
    padding-bottom: 5px!important;
  }
  .modal-body{
    padding: 0px 5px!important;
    padding-bottom: 10px!important;
  }
  .modal-footer{
    padding: 0px!important;
  }
  .app-name {
    font-size: 2rem;
    color: #fff;
    font-weight: bold;
  }
  .menu-container-item-2{
    .mobile{
      padding-left: 0px;
    }
  }
  .menu-container-item-1{
    .mobile{
      padding: 0px;
    }
    
  }
  .menu-container-item-3{
    .username-container{
      flex:2;
    }
    .lastname{
      padding-bottom: 0.3em;
    }
    .btn-container{
      flex:2
    }
    .version-container{
      flex:1;
      margin-bottom: 1em;
    }
    .firstname{
      margin-bottom:0px;
      line-height: 0.8em;
    }
  }
  .btn-width{
    width: 13.04em;
  }
}
