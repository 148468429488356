.landing-container{
    flex-direction: column;
    border-color: red 1px solid;
    width: 100%;
    height: 100vh;
    padding: 0px!important;
    .background-cyan{
        background: #55b5b1!important;
    }
    .color-white{
        color: #FFF!important;
    }
    .description-page{
        background: #000;
        color: #FFF;
        padding: 2em;
        margin: 0 auto;
        text-align: center;
        font-size: 1.2rem;
    }
    .first-page{
        display: flex;
        flex: 3;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        overflow: hidden;
        .header{
            display: flex;
            flex:1;
            background: #000;
            .logo-container{
                flex:3;
                padding-left: 2em;
                .logo{
                    width: 209px;
                    padding: 0.5rem;
                    margin-left: 3rem;
                }
            }
            .btn{
                padding: 0px;
                height: 50px;
                color: #FFF;
            }
            .btn:hover{
                color:#55b5b1;
            }
            .login-container{
                display: flex;
                flex:1;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .content{
                    display: flex;
                    margin-right: 10px;
                }

            }
            .separator{
                color: #FFF;
                font-size: 1.5rem;
                line-height: 2;
                margin:0 5px;
            }
        }
        
        .section{
            flex:8;
            background-image: url('/assets/img/header.png');
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            div{
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: left;
                margin-left: 6rem;
                .center{
                    color: white;
                    font-size: 2.5em ;
                    text-align: center;
                    font-weight: bold;
                    margin: 0 20% 0 20%;
                }
                .top{
                    margin-top: 0em;
                    width: 700px;
                    margin-left: 6em;
                }
                .bottom{
                    margin-bottom: 5em;
                }
                .btn-img{
                    background: none;
                    border: 0px solid;
                    // width: calc(16% + 1vw) ;
                    width: 300px;

                    img{
                        width: calc(180% + 1vw) ;
                        margin: 30px;
                    }
                    img:hover{
                        background: #bf896c78;
                    }
                }
                .phone{
                    position: absolute;
                    height: calc(35% + 1vw);
                    margin-right: 100px;
                }
                .download-container{
                    padding-right: 250px;
                    padding-bottom: 10em;
                }
            }
        }
    }

    .last-page{
        display: flex;
        flex: 3;
        flex-direction: column;
        height: auto;
        .section{
            flex:9;
            .download-container{
                display: flex;
                flex: 1;
                margin: 0 auto;
                padding-bottom: 70px;
                .android{
                    justify-content: right;
                    text-align: right;
                    margin-right: 30px;
                    height: 90px;
                }
                .ios{
                    justify-content: left;
                    text-align: left;
                    height: 90px;
                }
                .link-container{
                    flex:1;
                    display: flex;
                }
            }
            .ios-text-container{
                display: flex;
                justify-content: center;
                justify-items: center;
                align-items: center;
                align-content: center;
                padding-bottom: 3em;

                .text{
                    border: 0px solid #A4A4A485;
                    display: flex;
                    background: #bbe1df;
                    padding:0px 1em;
                    img{
                        height: 60px;
                        width: 60px;
                        margin-top: 20px;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                    }
                    .sc-bdVaJa{
                        font-size: 5em;
                        border-right: 1px solid #A4A4A485;
                    }
                    p{  
                        margin:10px;
                        padding: 10px;
                        color: #000;
                    }
                }
            }
        }
        .footer{
            flex:2;
            display: flex;
            background: #FFF;
            flex-direction: row;
            .left{
                flex: 1;
                float:left;
                padding-top: 2.5em;
                padding-left: 6em;
            }
            .right{
                flex: 1;
                img{
                    float:right
                }
                padding-right: 2.5em;
                padding-bottom: 1em;

            }
        }
    }

    .section{
        display: flex;
        flex:1;
        height: auto;
        flex-direction: column;
        .title-container{
            flex:1;
            padding: 2em;
        }
        .title{
            color: #55b5b1;
            text-align: center;
            flex: 1;
        }
        .spacing{
            flex:1;
        }
        .list-card{
            display: grid;
            flex:2;
            grid-template-columns: repeat(4, 4fr);
            padding: 0;
            width: 100%;
            .card{
                border-radius: 0px;
                width: 100%;
                align-items: center;
                background: #FFF;
                border: 2px solid #FFF;
                padding-top: 40px;
                padding-bottom: 40px;
                .img{
                    width:calc(15% + 1vw) ;
                }
                .title{
                    font-weight: bold;
                    padding-top: 1.5em;
                    color:#000;
                }
            }
        }

        .install-container{
            display: flex;
            flex: 1;
            .img-container{
                flex:1;
                display: flex;
                justify-content: center;
                padding-bottom: 3em;
                .img{
                    width: 60%;
                    height: auto;
                    display: block;   
                }
                
            }
            .text{
                flex:3;
                padding:5% 0 2em 5%;
                p{
                    font-size: 1.2rem ;
                    font-weight: 0px;
                }
            }
        }
    }

    .button-image{
        background: transparent;
        border: 0px solid #838383;
        height: 100px;
        width: 103px;
        display: flex;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .button-image:hover{
        background: #55b5b021;
    }
    .button-image:active{
        border: 1px solid #a8a4a4;
        background: #91919157;
    }
    
}


@media only screen and (max-width: 710px){ 
    /* smartphones, iPhone, portrait 480x320 phones */ 
    .landing-container {
        .section{
            .list-card{
                flex:5;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                .card {
                    border-radius: 0px;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid #FFF;
                    margin: 0px;
                    .img{
                        width:calc(20% + 1vw) ;
                    }
                    .text{
                        p{
                            font-size: calc(20% + 1vw) ;
                            font-weight: 0px;
                        }
                    }
                }
            }
        }
    }
    .landing-container .first-page .section div .center {
        color: white;
        font-size:calc(140% + 1vw)!important;
        text-align: center;
        font-weight: bold;
        margin: 0 30px
    }

    .landing-container .first-page .section div .btn-img {
        background: none;
        border: 0px solid;
        // width: calc(60% + 1vw);
        width:300px
    }
    .logo-container{
        flex:1;
        padding-left: 0;
        .logo{
            width: 95%;
            padding: 0;
        }
    }
    .landing-container .first-page .header .login-container {
        flex: 1 1;
        padding-right: 0px;
    }
    .section{
        .install-container{
            flex-direction: column-reverse;
        }
    }
    .landing-container .section {
        height: auto;
    }
    .go-login{
        font-size: 10px;
    }
    .landing-container .first-page .header .logo-container .logo {
        width: 150px;
    }

    .landing-container .last-page .footer .left {
        padding-top: 1em;
        padding-left: 10px;
        img{
            width: 150px;
        }
    }
    .landing-container .last-page .footer .right {
        padding-top: 1.5em;
        padding-right: 0.5em;
        img{
            width: 40px;
            padding-bottom: 5px;
            padding-right: 10px;
        }
    }
    .landing-container .last-page .section .download-container .android {
        margin-right: 0px;
    }
    .landing-container .last-page .section .download-container {
        margin: 0;
    }
    .landing-container .button-image {
        width: 150px;
        height: 70px;
        margin: 0 auto;
    }
    .landing-container .section .install-container .img-container {
        padding-bottom: 0em; 
    }

    .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
        padding-right: 0px!important; 
        padding-left: 0px!important; 
    }
    .landing-container .section .install-container .text {
        padding: 5% 11% 2em 11%;
    }
    .landing-container .first-page .header .logo-container {
        padding-left: 1em;
    }
    .landing-container .first-page .header {
        display: flex;
        flex: 0;
        height: 60px;
    }
    .landing-container .last-page .section .download-container {
        padding-bottom: 0px;
    }
    .last-page .section .title-container {
        padding: 1em;
    }
    .landing-container .last-page .section {
        .ios-text-container{
            .text{
                width: 320px;
                flex-direction: column;
                .sc-bdVaJa{
                    border: 0px solid #FFF;
                    margin:0 auto;
                    width: 100%;
                    border-bottom: 1px solid #A4A4A485;
                    padding: 15px;
                }
                p{  
                    padding:0px;
                    font-size: 13px;
                }
            }
        }
    }
    .landing-container .first-page .section div .top {
        margin-top: -2em;
        margin-left: 0em;
        width: 330px;
    }
    .landing-container .first-page .section div .download-container {
        padding-right: 100px;
        padding-bottom: 245px;
    }
    .landing-container .first-page .section div .phone {
        position: absolute;
        height: calc(40% + 1vw);
        margin-right: auto;
        left: -10px;
    }
    .landing-container .first-page .header .logo-container {
        flex: 1;
        padding-left: 2em;
    }
    .landing-container .first-page .header .login-container {
        flex: 1;
        padding-right: 0px;
        justify-content: center;
    }
    .landing-container .first-page .header .btn {
        font-size: 10px;
    }
    .landing-container .first-page .header .separator {
        font-size: 14px;
        line-height: 2;
        margin: 11px 5px;
    }
    .landing-container .first-page .section div .btn-img {
        background: none;
        border: 0px solid;
        width: 155px;
        margin-right: 5em;
        img{
            width: calc(205% + 1vw);
            margin: auto;
        }
    }
    .landing-container .last-page .section .ios-text-container .text{
        flex-direction: row;
        width: 433px;
        img{
            height: 55px;
            width: 55px;
            margin: 10px;
        }
    } 
    .landing-container .last-page .section .ios-text-container .text {
        padding: 0 1em;
        p{
            font-size: 11px!important;
        }
    }
    .landing-container .last-page .section .download-container {
        justify-content: center;
    }
    .landing-container .section .install-container .text p {
        font-size: 1rem;
    }
    .landing-container .last-page .footer .right img {
        height: 72px;
    }
    .landing-container .last-page .footer .right img {
        width: auto;
    }
    .landing-container .last-page .footer .left img {
        padding-top: 29px;
    }
    .landing-container .first-page .header .logo-container .logo {
        margin-left: 0rem;
    }
    .landing-container .first-page .section div {
        margin-left: 1.2rem;
    }
    .landing-container .first-page .section {
        background-image: url("/assets/img/header-mb.png");
    }
    .landing-container .first-page .section div .download-container {
        padding-right: 100px;
        padding-bottom: 200px;
    }
    .landing-container .first-page .section div .top {
        margin-top: -9em;
        margin-left: 0em;
        width: 350px;
    }

}

@media (min-width:711px) and (max-width:961px)  { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */ 
    .landing-container .first-page .section div .btn-img {
        width: 300px;
    }
    .landing-container .first-page .section div .btn-img img {
        margin: 0.1rem;
    }

    .landing-container .last-page .section .download-container .android {
        padding-right: 30px;
        // margin-right: 0px;
    }
    .landing-container .last-page .footer .left {
        padding-left: 2em;
    }
    .landing-container .last-page .footer .right {
        padding-right: 1.5em;
    }
    .landing-container .last-page .section .download-container {
        margin: 0 auto;
    }
    .landing-container .last-page .section {
        .ios-text-container{
            .text{
                margin:auto 10px;
            }
            p{  
                    padding:0px;
            }
        }
    }
    .landing-container .first-page .section div .top {
        width: auto;
        margin-top: 0em;
    }
    .landing-container .first-page .section div .download-container {
        padding-right: 110px;
        padding-bottom: 340px;
    }
    .landing-container .first-page .header .logo-container {
        flex: 1 1;
        padding-left: 2em;
    }
    .landing-container .first-page .header .login-container {
        align-items: center;
        justify-content: center;
    }
    .landing-container .first-page .section div {
        margin-left: 3rem;
    }

}
@media (min-width:500px) and (max-width:961px) and (orientation: landscape) { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */ 
    .landing-container .first-page .section div .btn-img {
        width: calc(25% + 1vw);
    }
    .landing-container .first-page .section div .top {
        width: calc(25% + 0.1vw);
        margin-top: 0em;
        margin-left: 0em;
    }
    .landing-container .last-page .section .download-container .android {
        padding-right: 30px;
    }
    .landing-container .first-page .section div .center {
        font-size: 1.7em;
    }
    .landing-container .first-page .section div .bottom {
        margin-bottom: 0em;
    }
    .landing-container .last-page .footer .left {
        padding-left: 2em;
    }
    .landing-container .last-page .footer .right {
        padding-right: 1.5em;
    }
    .landing-container .first-page .header .logo-container .logo {
        height: 70px;
    }
    .landing-container .button-image {
        width: 245px;
        margin: 0 auto;
    }
    .landing-container .last-page .section .download-container {
        margin: 0 auto;
    }
    .landing-container .last-page .section {
        .ios-text-container{
            .text{
                margin:auto 10px;
            }
            p{  
                padding:0px;
            }
        }
    }
    .landing-container .first-page .section div .top {
        width: calc(65% + 0.1vw);
        margin-top: 0em;
    }
    .landing-container .first-page .section div .download-container {
        padding-right: 110px;
        padding-bottom: 7px;
    }
    .landing-container .first-page .header .logo-container {
        flex: 1 1;
        padding-left: 2em;
    }
    .landing-container .first-page .header .login-container {
        align-items: center;
        justify-content: center;
    }
}
@media (min-width:700px) and (max-width:1024px) {
    
    .landing-container .first-page .section div .top {
        width: calc(90% + 0.1vw);
        margin-top: 0em;
        margin-left: 0em;
    }
    .landing-container .first-page .section div .download-container {
        padding-bottom: 2em;
    }
    
} 
@media (max-width:320px) {
    .landing-container .first-page .section div .top {
        margin-top: -5em;
        margin-left: 0em;
        width: 285px;
    }
    .landing-container .first-page .section div .phone {
        position: absolute;
        height: calc(37% + 1vw);
        margin-right: auto;
        left: -10px;
    }
    .landing-container .first-page .section div .btn-img img {
        width: calc(182% + 1vw);
    }
}
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
        .landing-container .first-page .section div .phone {
            position: absolute;
            height: calc(35% + 1vw);
            margin-right: 100px;
        }
}
@media all and (device-width: 1024px) and (device-height: 1366px) {
    .landing-container .first-page .section div .phone {
        position: absolute;
        height: calc(35% + 1vw);
        margin-right: 100px;
    }
}
@media (max-width:1360px) {
    .landing-container .first-page .section div .top {
        margin-top: 0em;
    }
    .landing-container .first-page .section div .download-container {
        padding-bottom: 0em;
    }
}


