@import './theme';

// $border-radius: 1rem;
// $card-inner-border-radius: 1rem;

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';
@import '~bootswatch/dist/flatly/bootswatch';
#root{
  // overflow: hidden!important;
  // width: 100vw;
  // height: 100vh;
  display: flex;
  flex-direction: column;
}

.division {
  border-top: 1px solid #aaa;
  text-align: center;
  display: block;
  margin: 1rem;

  label {
    padding-left: 0.5rem;
  }
}

.footer {
  height: 2rem;
}


.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #0099cc;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
}


.loading-indicator,
.load-more { 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  &__text {
    padding: 0;
    margin: 0 5px;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

