.card {
  margin: 10px 0;

  .card-text-full {
    padding: 5px;
  }

  &.parent {
    margin-bottom: 0;
  }

  &.child {
    margin-bottom: 0;
    margin-top: 0;
    // padding-left: 20px;
    // background-color: rgba(255, 255, 255, 0.3);
    background-color: #e3e3e3;
    // color: #fff;
  }

  p {
    padding: 0;
    margin: 0;
  }
}

.card-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;

  &__block {
    width: 100%;
  }

  &__content {
    margin-left: 0;
    margin-right: 0;
    align-items: center;
    display: grid !important;
    grid-template-rows: 4fr 1fr;
  }

  .image-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .buttons-control {
    height: 100%;
    grid-column: 2 / -1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .card-body {
    width: 100%;
    padding-left: 10px;
    padding-top: 5px;
  }

  button {
    margin-right: 0.5rem;
  }

  .img-container {
    width: 100%;
    position: relative;
    display: flex;
    max-height: 200px;

    img {
      /* object-fit: cover;*/
      max-height: fit-content;
      max-width: 100%;
    }
  }

  .card-header-content {
    vertical-align: top;
    img {
      float: left;
      margin-right: 5px;
      width: 4rem;
      margin-right: 15px;
    }

    .card-title-header{
      font-size: 14px;
      line-height: 1;
      font-weight: bold;
      color: #0099cc;
      /*text-transform: uppercase;*/
      padding-bottom: 0.5rem;
      padding-top: 1.1em;
    }

    .tags {
      ul{
        display: flex;
        li{
          margin: 0px 2px;
          list-style-type: none;
        }
      }
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .complete-text,
  .summary {
    font-size: 14px;
  }

  .summary {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /* max-height: 50px;*/
    overflow: hidden;
  }

  .summary.full {
    max-height: initial;
    -webkit-line-clamp: initial;
  }

  .view-more {
    &.footer {
      margin-top: 1rem;
      border-top: 1px solid #d9d9d9;
    }
  }

  .link {
    margin: 0 5px;
  }
}

.card.synthesis,
.card.inform {
  .buttons-control {
    display: block;
  }
  .content-wrapper {
    width: 100%;
  }

  .pre-title {
    padding-top: 5px;
    padding-left: 15px;
    font-weight: bold;
    font-size: 15px;
    text-transform: uppercase;
    color: #0099cc;
    text-align: left;
  }

  .date {
    padding-top: 5px;
    font-weight: bold;
    font-size: 15px;
    padding-left: 5px;
    text-transform: uppercase;
    color: #0099cc;
    text-align: right;
  }

  .title {
    padding-top: 5px;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.modal-90w {
  .container-fluid {
    padding: 0;
  }
}

.modal-full-h {
  .modal-body {
    height: 90vh;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .modal-90w {
    width: 90%;
    max-width: none !important;
  }

  .card-body {
    height: 100%;
  }

  .card-wrapper {
    /* max-height: 200px;*/

    &.full {
      max-height: initial;
    }

    &__content {
      height: 100%;
    }

    .buttons-control {
      justify-content: flex-end;
      padding-right: 10px;
    }
  }

  .datetime-content{
    float: right;
  }
}
