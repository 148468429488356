.container.app-header {
  padding-top: calc(env(safe-area-inset-top) + 5px) !important;
  width: 100%;
  flex: 1;
  top: 0;
  z-index: 100;
  background-color: #fff;
  padding: 0 30px 0 30px;
  max-width: initial;
  background: transparent;

  .header {
    height: 90px;


    .main-logo {
      position: absolute;
      left:15px;
      max-width:  80px;
      top: 10px;
    }
    .logo {
      right: 15px;
      position: absolute;
      height: auto;
      padding-top: 16px;
      width:160px !important;
    }
  }

  .menu-search {
    display: flex;

    .search {
      display: flex;
      width: 100%;
      margin-left: 0.5rem;

      &.disabled {
        background-color: #0a0a0a;
      }

      input {
        background-color: transparent;
        color: #454545;
        border-radius: 0 !important;
        outline:none !important;
        outline-width: 0 !important;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
      }

      button {
        padding: 0 15px;
        border-radius: 0 !important;
      }

    }

    button {
      padding: 0;
    }
  }

  .tab-container {
    margin: 1rem;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;

    .tab-header-title {
      margin: 0 0.5rem;
      font-weight: bold;
      color: #0099cc;
      text-transform: uppercase;

      &:hover {
        cursor: pointer;
      }

      &.active {
        border-bottom: 1px solid #cc0000;
      }

      .has-unread {
        background-color: #cc0000;
        border-radius: 50%;
        width: 6px;
        height: 6px;
      }
    }
  }
  .tab-container::-webkit-scrollbar {
    display: none;
  }
}


@media (min-width: 768px) {
  .container.app-header {
    .tab-container {
        display: flex;
        justify-content: center;
        overflow-x: hidden;
    }
  }
}
